define("discourse/plugins/discourse-mantis-ads/discourse/initializers/mantis-ads", ["exports", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/render-modifiers/modifiers/did-update", "rsvp", "discourse/lib/api", "discourse/lib/load-script", "discourse/lib/url", "discourse-common/config/environment", "I18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _didInsert, _willDestroy, _didUpdate, _rsvp, _api, _loadScript, _url, _environment, _I18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* 
  
  How this works?
  ---
  - Pri prvnim nacteni tematu se vlozi <div data-mantis-zone="topic-above-suggested" a pri renderu se zavola prvotni inicializace mantisu
  
  - Volani window.mantis.push(['display', 'load', { property: '5e7ca88aea22070008b8f2a3' }]);
    Da do fronty prikaz "mantis load", tzn. az se externi skript nacte, ozivi vsechny <div data-mantis-zone= ..>
  
  - Nasledne pri kazde zmene stranky, kde url je '/t/' tedy topic dojde k prikazu "mantis refresh"
  
  
  
  
  
  
  
  1. Jednou na zacatku globalne nacist externi skript
  
  <script type="text/javascript" data-cfasync="false">
    var mantis = mantis || [];
    mantis.push(['display', 'load', {
      property: '5e7ca88aea22070008b8f2a3'
    }]);
    </script>
  <script src="mantodea.min.js"></script>
  
  
  2. Definovat plochu
  
  ```<div id="zone1" data-mantis-zone="topic-above-suggested"></div>```
  
  
  3. pri kazdem zobrazeni custom divu volat mantis refresh
  
  mantis.push(['display', 'refresh', 'topic-above-suggested']);
  
  
  Q: did insert je jednou nebo vicekrat?
  A: Didinsert je jen pri renderu, tzn. kdyz se prepina ze stranky jine nez topic, do stranky topic. Tzn. nikoliv pri prepnuti mezi topicy
  
  - lze detekovat kdyz se dostane do viewportu?
  ?
  
  Life Cycle:
  ---
  mantis si ridi sam nacteni, tzn. volani mantis.push funguje pred nactenim externiho skriptu i po nem (ma vlastni implementovanou frontu)
  
  
  didInsert -> aktivovat observer
  
  didUpdate -> aktivovat observer
  
  willDestory -> deaktivovat observer
  
  
  observer pri intersecting bude volat na prvni pokus "load", na kazdy dalsi "refresh"
  
  
  */ /* Add custom field saving in user preferences -> interface to controller */function addSetting(api) {
    api.modifyClass("controller:preferences/interface", {
      pluginId: "discourse-mantis-ads",
      actions: {
        save() {
          this.set("model.custom_fields.banner_opt_out", this.get("model.banner_opt_out"));
          this.get("saveAttrNames").push("custom_fields");
          this._super();
        }
      }
    });
  }
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    const router = api.container.lookup("router:main");
    const siteSettings = api.container.lookup("service:site-settings");
    const currentUser = api.getCurrentUser();
    if (siteSettings.mantis_ads_enabled) {
      addSetting(api, siteSettings);
    }
    // define observer 
    let adview_observer;
    function pluginDebug(msg) {
      if (siteSettings.mantis_ads_enable_debug_mode) {
        console.log(msg);
      }
    }
    pluginDebug("Mantis Ads Plugin Enabled setting accessible: " + siteSettings.mantis_disable_ads_for_groups);
    // verify that user is not among disabled usergroups
    // Doesn't work for 'everyone' -> to disable for everyone, disable whole plugin
    // verify that user didn't opted-out
    function dontShowAdsToUser(currentUser, siteSettings) {
      if (currentUser) {
        if (currentUser.get("custom_fields.banner_opt_out")) {
          pluginDebug("Mantis Ads: User custom setting banner_opt_out: " + currentUser.get("custom_fields.banner_opt_out"));
          return true;
        }
      }
      const disableAdsGroupIds = siteSettings.mantis_disable_ads_for_groups.split('|');
      if (currentUser && currentUser.groups && disableAdsGroupIds) {
        const userInDisabledGroup = disableAdsGroupIds.some(id => currentUser.groups.some(group => group.id.toString() === id));
        pluginDebug("Mantis Ads: User has ads disabled: " + userInDisabledGroup);
        return userInDisabledGroup;
      } else {
        pluginDebug("Mantis Ads: User has ads enabled");
        return false;
      }
    }
    const shouldDisplay = () => siteSettings.mantis_ads_enabled && !dontShowAdsToUser(currentUser, siteSettings);
    // Load non-module JS script (URL in admin settings)
    (0, _loadScript.default)(siteSettings.mantis_ads_js_lib);
    //loadScript("https://assets.mantisadnetwork.com/mantodea.min.js");
    // Load non-module JS script from /public/javascripts
    //loadScript("/plugins/discourse-mantis-ads/javascripts/mantodea.min.js");
    /*
    // nacist knihovnu a monitorovat stav nacteni - neni potreba
    // loading flag
    window.mantis_loading = true;
    Promise.all([
    // Load non-module JS script externally
    // loadScript("https://assets.mantisadnetwork.com/mantodea.min.js"),
     // Wish list: js script url to settings
     // Load non-module JS script from /public/javascripts
    //loadScript("/plugins/discourse-mantis-ads/javascripts/mantodea.min.js"),      
    loadScript(siteSettings.mantis_ads_js_lib),      
    ]).then(() => {
    document.body.classList.add("mantisads-loaded");
     // ulozit stav ze knihovna je ready na call
    window.mantis_loading = false;
    window.mantis_loaded = true;
    });
    */ // Function to display the ad when the div is in the viewport
    function displayAd() {
      let i_observer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      window.mantis = window.mantis || [];
      if (!window.mantis_initialized) {
        pluginDebug("Mantis Ads: command 'Load'");
        window.mantis.push(['display', 'load', {
          property: '5e7ca88aea22070008b8f2a3'
        }]);
        window.mantis_initialized = true;
      } else {
        pluginDebug("Mantis Ads: command 'Refresh'");
        window.mantis.push(['display', 'refresh', 'topic-above-suggested']);
      }
      // Disconnect the observer after loading or refreshing the ad to prevent multiple loads
      // Check if i_observer is not null before disconnecting
      if (i_observer) {
        i_observer.disconnect();
      }
    }
    // Create the Intersection Observer
    function createObserver() {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // If the ad div is visible, display the ad
            pluginDebug("Mantis Ads: Observer.isIntersecting");
            displayAd(observer);
          }
        });
      });
      return observer;
    }
    /* 
    Creates observer connected to a div
    which upon coming into viewport (intersecting)
    calls mantis with either load or referesh command
    */
    function initializeAdZone() {
      pluginDebug("Mantis Ads: didInsert modifier called");
      if (siteSettings.mantis_lazy_load_ads) {
        initializeObserver();
      } else {
        displayAd();
      }
    }
    /* 
    Disconnects observer to end its lifecycle
    */
    function destroyAdZone() {
      pluginDebug("Mantis Ads: willDestroy modifier called");
      // disconnect old observer
      if (adview_observer) {
        pluginDebug("Mantis Ads: Disconnecting old observer");
        //console.dir(adview_observer);
        adview_observer.disconnect();
      }
    }
    function initializeObserver() {
      // get mantis object or create one (will be read upon mantis library init, it can handle both)
      window.mantis = window.mantis || [];
      // disconnect old observer
      if (adview_observer) adview_observer.disconnect();
      // Start observing the ad div
      const adDiv = document.getElementById('mantis_zone_1');
      if (adDiv) {
        // reuse old observer
        //console.dir(adview_observer);
        adview_observer = adview_observer || createObserver();
        adview_observer.observe(adDiv);
        pluginDebug("Mantis Ads: Start observing div");
      }
    }
    function updateAdZone(outletargs) {
      pluginDebug("Mantis Ads: didUpdate modifier called");
      if (siteSettings.mantis_lazy_load_ads) {
        initializeObserver();
      } else {
        displayAd();
      }
      //console.dir(outletargs);
    }
    api.renderInOutlet('topic-above-suggested', (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (shouldDisplay)}}
          <!-- Mantis Ads -->
          <div class="mantis-container">
            <div 
              class="mantis-banner" 
              id="mantis_zone_1" 
              data-mantis-zone="topic-above-suggested" 
              {{didInsert initializeAdZone}}
              {{willDestroy destroyAdZone}}
              {{didUpdate updateAdZone @outletArgs}}
            ></div>
          </div>
          <br />
        {{/if}}
      
    */
    {
      "id": "BZTzppIh",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],null,null],[[[1,\"      \"],[3,\" Mantis Ads \"],[1,\"\\n      \"],[10,0],[14,0,\"mantis-container\"],[12],[1,\"\\n        \"],[11,0],[24,0,\"mantis-banner\"],[24,1,\"mantis_zone_1\"],[24,\"data-mantis-zone\",\"topic-above-suggested\"],[4,[32,1],[[32,2]],null],[4,[32,3],[[32,4]],null],[4,[32,5],[[32,6],[30,1]],null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-mantis-ads/discourse/initializers/mantis-ads.js",
      "scope": () => [shouldDisplay, _didInsert.default, initializeAdZone, _willDestroy.default, destroyAdZone, _didUpdate.default, updateAdZone],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
    /*
      api.onPageChange((url, title) => {
        pluginDebug('the page changed to: ' + url + ' and title ' + title);
      });
      */ /*api.onAppEvent('topic:current-post-changed', () => {
         //const adsDivElement = document.querySelector('.mantis-banner');
         //if (adsDivElement && adsDivElement.innerHTML === 'Mantis zone placeholder' && window.mantis) {
         //  mantis.push(['display', 'refresh', 'topic-above-suggested']);
         console.log("Event: topic:current-post-changed");
         //}
         });
         */ //router.on("routeDidChange", this, currentRouteChanged);
  });
});